@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow: hidden;
}

.page-link {
  @apply px-2 md:px-3 py-2 text-sm font-medium;
}

.pagination_active {
  @apply text-xs md:text-sm  rounded-lg cursor-pointer text-blue bg-blue  bg-opacity-5;
}
.pagination-container {
  @apply flex items-center flex-wrap md:flex-nowrap gap-2 md:gap-3;
}
.pagination-disabled {
  @apply opacity-20;
}

.medparagraph {
  font-family: DM Sans;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.status {
  @apply px-3 py-1 flex font-medium items-center justify-center text-center;
  &.active {
    @apply bg-green-200  text-green-800 rounded-lg capitalize;
  }
  &.inactive {
    @apply bg-red-200  text-red-800 rounded-lg capitalize;
  }
}

button {
  &.primary {
    @apply bg-primary text-white border border-primary;
  }
  &.outline {
    @apply text-primary bg-white border border-primary border-opacity-10;
  }
  &.purpleoutline {
    @apply text-purple2 bg-white border-2 border-purple2;
  }
  &.purple {
    @apply bg-purple2 text-white border border-purple2;
  }
  &.gray {
    @apply bg-[#151411] bg-opacity-40 text-white;
  }
}
.newtons-cradle {
  --uib-size: 50px;
  --uib-speed: 1.2s;
  --uib-color: #09066b;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--uib-size);
  height: var(--uib-size);
}

.newtons-cradle__dot {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 25%;
  transform-origin: center top;
}

.newtons-cradle__dot::after {
  content: '';
  display: block;
  width: 100%;
  height: 25%;
  border-radius: 50%;
  background-color: var(--uib-color);
}

.newtons-cradle__dot:first-child {
  animation: swing var(--uib-speed) linear infinite;
}

.newtons-cradle__dot:last-child {
  animation: swing2 var(--uib-speed) linear infinite;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }

  25% {
    transform: rotate(70deg);
    animation-timing-function: ease-in;
  }

  50% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
}

@keyframes swing2 {
  0% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }

  50% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }

  75% {
    transform: rotate(-70deg);
    animation-timing-function: ease-in;
  }
}

.header {
  @apply text-base sm:text-xl font-medium;
}

body {
  color: #151411;
  font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(191, 191, 191, 0.15);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fullflex {
  @apply flex items-center justify-between;
}

.card {
  @apply bg-white px-5 py-4 rounded-lg;
}

.paginationWrapper {
  @apply flex items-center justify-center p-1.5 md:p-3 border rounded-lg cursor-pointer border-gray4 hover:opacity-70;
}

p {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.45767441391944885px;
}
